var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Notes", {
    attrs: {
      title: "Store Notes",
      noteType: "STORE",
      hintText:
        "Please add any important store notes regarding the menu or special instructions here",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }