<template>
  <Notes title="Store Notes" noteType="STORE" hintText="Please add any important store notes regarding the menu or special instructions here"/>
</template>

<script>
import Notes from "../components/notes.vue";

export default {
    name: "StoreNotes",
    components: {
        Notes,
    },
    data() {
        return {
      
        };
    },
    mounted() {

    }
};
</script>
